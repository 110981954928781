var vm = new Vue({
    el: '#mt-add-to-cart-modal',
    data: {
        imageWidth: {
            addToCartModal: 100
        },
        modalButtonText: 'Secure Checkout',
        modalDisplayName: '',
        modalHeading: 'Added to Cart!',
        modalImageUrl: '',
        modalMerchandiseSubtotal: 0,
        modalQuantity: 0,
        modalSubtotal: 0,
        modalTotalItems: 0,
        resizerParam: '?width='
    },
    methods: {
        addToCartMonetate(skuCode) {
            var viewModel = {
                SkuCode: skuCode,
                Quantity: 1,
                Alias: null
            };

            $.ajax({
                method: "POST",
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify(viewModel),
                dataType: "json",
                url: '/api/CartItem',
                headers: { "__RequestVerificationToken": $("input[name='__RequestVerificationToken']").val() }
            })
            .then(data => {
                this.modalDisplayName = data?.AddedCartItem?.DisplayName.trim();
                this.modalImageUrl = data?.AddedCartItem?.ImageUrl;
                this.modalMerchandiseSubtotal = data?.MerchandiseSubtotal;
                this.modalQuantity = data?.AddedCartItem?.Quantity;
                this.modalSubtotal = data?.AddedCartItem?.Subtotal;
                this.modalTotalItems = data?.TotalNumberOfItems;

                // update cart header count
                cei.shared.cart.updateCartHeaderQuantity(1);
         
                $("#mt-add-to-cart-modal").modal('show');

            })
            .catch(error => {
                console.error(error);
            });
        }
    }
});
